import Spinner from './Spinner';

function LoadingScreen() {
  return (
    <div className="max-w-md mx-auto my-10 flex flex-col items-center justify-center gap-2">
      <div className="flex">
        <img
          alt="logo"
          className="h-14 mb-3 object-contain"
          src="/logo.png"
        />
      </div>
      <Spinner />
    </div>
  );
}

export default LoadingScreen;
