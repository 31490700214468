/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import cx from 'classnames';
import { Label, TextInput } from 'flowbite-react';
import { useState } from 'react';
import { MdAdd } from 'react-icons/md';

import useCard from '@/api/useCard';
import useForm from '@/hooks/useForm';

const INITIAL_FORM = {
  name: '',
  initialBalance: '',
};

function CardCreateButton() {
  const [isOpen, setIsOpen] = useState(false);
  const [type, setType] = useState<'debit' | 'credit'>('debit');
  const card = useCard();

  const {
    name, initialBalance, handleInputChange, reset,
  } = useForm(INITIAL_FORM);

  const handleOpenForm = () => {
    reset(INITIAL_FORM);
    setIsOpen(true);
  };

  const handleSubmitCard = (e: any) => {
    e.preventDefault();
    card.mutateAsync({
      name,
      type,
      initial_balance: Number(initialBalance),
    });
    setIsOpen(false);
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-floating bottom-20 right-4"
        onClick={handleOpenForm}
      >
        <MdAdd />
      </button>
      <div
        className={cx('bg-black opacity-10 fixed w-full h-screen left-0 right-0 top-0', {
          block: isOpen,
          hidden: !isOpen,
        })}
        onClick={() => setIsOpen(false)}
      />
      <div className={cx(
        'z-[90] h-[80vh] w-full fixed bottom-0 bg-white rounded-t-xl p-4 left-0 right-0 shadow-inner',
        'transition-transform duration-500 ease-in-out',
        {
          'transform translate-y-0': isOpen,
          'transform translate-y-full': !isOpen,
        },
      )}
      >
        <h2 className="mb-4">Agregar una Cuenta</h2>
        <form onSubmit={handleSubmitCard}>
          <div className="form-group">
            <div className="flex w-full">
              <button
                type="button"
                className={cx('btn flex-grow rounded-r-none', { 'btn-outline': type === 'debit' })}
                onClick={() => setType('debit')}
              >
                Debito
              </button>
              <button
                type="button"
                className={cx('btn flex-grow rounded-l-none', { 'btn-outline': type === 'credit' })}
                onClick={() => setType('credit')}
              >
                Cr&eacute;dito
              </button>
            </div>
          </div>
          <div className="form-group">
            <Label htmlFor="name">Nombre</Label>
            <TextInput name="name" id="name" placeholder="Nombre" type="text" value={name} onChange={(e) => handleInputChange(e.target)} />
          </div>
          <div className="form-group">
            <Label htmlFor="initialBalance">Saldo Inicial</Label>
            <TextInput name="initialBalance" id="initialBalance" placeholder="Monto" type="number" value={initialBalance} onChange={(e) => handleInputChange(e.target)} />
          </div>
          <div className="w-full flex justify-end gap-4 mt-4">
            <button
              type="button"
              className="btn btn-outline"
              onClick={() => setIsOpen(false)}
            >
              Cancelar
            </button>
            <button
              type="submit"
              className="btn"
            >
              Guardar
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default CardCreateButton;
