import { useState } from 'react';

const useForm = <T extends Object>(initialState: T) => {
  const [values, setValues] = useState(initialState);
  const [isFormValid, setIsFormValid] = useState(false);
  const [formError, setFormError] = useState('');
  const [isFormLoading, setIsFormLoading] = useState(false);

  const handleInputChange = (target: EventTarget & HTMLInputElement | EventTarget & HTMLSelectElement | EventTarget & HTMLTextAreaElement | { name: string, value: unknown }) => {
    setValues({
      ...values,
      [target.name]: target.value,
    });
  };

  const reset = (value: T) => {
    setValues(value);
  };

  return {
    ...values,
    handleInputChange,
    reset,
    isFormValid,
    setIsFormValid,
    formError,
    setFormError,
    isFormLoading,
    setIsFormLoading,
  };
};

export default useForm;
