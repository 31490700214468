/* eslint-disable max-len */
import Header from '@/components/Header';

function PrivacyPolicy() {
  return (
    <>
      <Header />

      <div className="max-w-7xl py-20 m-auto text-justify flex flex-col gap-2">
        <h1 className="mb-4">Política de Privacidad</h1>

        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam vero nisi aliquam explicabo asperiores architecto deserunt quod vitae provident modi incidunt, voluptatibus sed suscipit deleniti saepe praesentium rerum nobis accusamus?</p>

      </div>
    </>
  );
}

export default PrivacyPolicy;
