import { useQuery } from 'react-query';
import { axios } from './axios';

async function getCards() {
  const res = await axios.get<Api.Response<Api.Card[]>>('/cards');
  return res.data;
}

const useCards = () => {
  const query = useQuery('cards', getCards);
  return query;
};

export default useCards;
