import { IoMdCash } from 'react-icons/io';
import { MdOutlineCreditCard } from 'react-icons/md';
import { VscCreditCard } from 'react-icons/vsc';

import useCards from '@/api/useCards';
import FetchingIndicator from '@/components/FetchingIndicator';
import SkeletonList from '@/components/SkeletonList';
import CardCreateButton from '@/features/cards/CardCreateButton';

function WalletsPage() {
  const cards = useCards();

  if (cards.isLoading) return <SkeletonList />;

  return (
    <>
      <div className="relative">
        {cards.isFetching && <FetchingIndicator />}
        <ul>
          {cards.data?.data?.map((card) => (
            <li key={card.id} className="p-4 shadow-md rounded-md bg-gray-50 mb-2">
              <div className="flex items-center">
                <div className="pr-2">
                  {card.is_cash === 'yes' && <IoMdCash className="w-8 h-8" />}
                  {(card.is_cash === 'no' && card.type === 'debit') && <MdOutlineCreditCard className="w-8 h-8" />}
                  {(card.is_cash === 'no' && card.type === 'credit') && <VscCreditCard className="w-8 h-8" />}
                </div>
                <div className="flex-grow">
                  {card.name}
                </div>
                <div className="grid items-center">
                  <span className="font-bold text-lg">{card.balance}</span>
                </div>
              </div>
            </li>
          ))}

        </ul>
      </div>
      <CardCreateButton />
    </>
  );
}

export default WalletsPage;
