import { useRoutes } from 'react-router-dom';

import AppLayout from '@/components/AppLayout';
import useAuth from '@/hooks/useAuth';
import HomePage from '@/pages/HomePage';
import LoginPage from '@/pages/LoginPage';
import NotFoundPage from '@/pages/NotFoundPage';
import PrivacyPolicy from '@/pages/PrivacyPolicy';
import ProfilePage from '@/pages/ProfilePage';
import SettingsPage from '@/pages/SettingsPage';
import TermsPage from '@/pages/TermsPage';
import WalletsPage from '@/pages/WalletsPage';

export function AppRoutes() {
  const { isAuthenticated } = useAuth();
  const routes = [];

  if (isAuthenticated) {
    routes.push({
      path: '/',
      element: <AppLayout />,
      children: [
        { path: '/', element: <HomePage /> },
        { path: 'wallets', element: <WalletsPage /> },
        { path: 'settings', element: <SettingsPage /> },
        { path: 'profile', element: <ProfilePage /> },
      ],
    });
  } else {
    routes.push({ path: '/', element: <LoginPage /> });
  }

  routes.push(
    {
      path: '/legal',
      children: [
        { path: 'politica-privacidad', element: <PrivacyPolicy /> },
        { path: 'condiciones-del-servicio', element: <TermsPage /> },
      ],
    },
    {
      path: '*',
      element: <NotFoundPage />,
    },
  );

  const element = useRoutes(routes);
  return element;
}

export default AppRoutes;
