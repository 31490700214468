import { useMutation } from 'react-query';
import { axios } from './axios';

async function postTransaction(transaction: Partial<Api.Transaction>) {
  const res = await axios.post<Api.Response<Api.Transaction>>('/transactions', transaction);
  return res.data;
}

const useTransaction = () => {
  const makeTransaction = useMutation(postTransaction);
  return makeTransaction;
};

export default useTransaction;
