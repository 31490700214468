import { Outlet } from 'react-router-dom';
import Footer from './Footer';
import Header from './Header';

function AppLayout() {
  return (
    <div className="flex flex-col h-screen">
      <Header />
      <div className="p-4 flex-grow">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}

export default AppLayout;
