import cx from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  MdAccountCircle, MdOutlineSettings, MdTimeline, MdWallet,
} from 'react-icons/md';

function Footer() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <div className="sticky bottom-0">
      <div className="sticky bottom-0 left-0 z-50 w-full h-16 bg-white border-t border-gray-200 dark:bg-gray-700 dark:border-gray-600">
        <div className="grid h-full max-w-lg grid-cols-4 mx-auto font-medium">
          <button
            onClick={() => navigate('/')}
            type="button"
            className={cx(
              'inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group',
              pathname === '/' ? 'text-main' : 'text-gray-500',
            )}
          >
            <MdTimeline className="w-5 h-5 mb-2" />
            <span className="text-sm">
              Gastos
            </span>
          </button>
          <button
            onClick={() => navigate('/wallets')}
            type="button"
            className={cx(
              'inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group',
              pathname === '/wallets' ? 'text-main' : 'text-gray-500',
            )}
          >
            <MdWallet className="w-5 h-5 mb-2" />
            <span className="text-sm">
              Cuentas
            </span>
          </button>
          <button
            onClick={() => navigate('/settings')}
            type="button"
            className={cx(
              'inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group',
              pathname === '/settings' ? 'text-main' : 'text-gray-500',
            )}
          >
            <MdOutlineSettings className="w-5 h-5 mb-2" />
            <span className="text-sm">
              Configuraci&oacute;n
            </span>
          </button>
          <button
            onClick={() => navigate('/profile')}
            type="button"
            className={cx(
              'inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group',
              pathname === '/profile' ? 'text-main' : 'text-gray-500',
            )}
          >
            <MdAccountCircle className="w-5 h-5 mb-2" />
            <span className="text-sm">
              Perfil
            </span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Footer;
