import { useQuery } from 'react-query';
import { axios } from './axios';

export function getSignedInUser() {
  return axios.get<{ authInfo: Api.User, token: string }>('/auth/me');
}

export const useSignIn = () => {
  const query = useQuery('signedInUser', getSignedInUser, {
    retry: false,
    refetchOnWindowFocus: false,
  });

  return {
    ...query,
    signedInUser: query.data?.data?.authInfo,
    token: query.data?.data?.token,
  };
};
