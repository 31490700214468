import { useQuery } from 'react-query';
import { axios } from './axios';

async function getTransactions() {
  const res = await axios.get<Api.Response<Api.Transaction[]>>('/transactions');
  return res.data;
}

const useTransactions = () => {
  const transactions = useQuery('transactions', getTransactions);

  return transactions;
};

export default useTransactions;
