import cx from 'classnames';
import { Label } from 'flowbite-react';

import useTransactions from '@/api/useTransactions';
import FetchingIndicator from '@/components/FetchingIndicator';
import SkeletonList from '@/components/SkeletonList';
import TransactionCreateButton from '@/features/transactions/TransactionCreateButton';

function HomePage() {
  const transactions = useTransactions();

  if (transactions.isLoading) return <SkeletonList />;

  return (
    <>
      <div className="relative">
        {transactions.isFetching && <FetchingIndicator />}
        <ul>
          {transactions.data?.data.length === 0 && <li className="p-4 shadow-md rounded-md bg-gray-50">No hay movimientos</li>}
          {transactions.data?.data.map((transaction) => (
            <li key={transaction.id} className="p-4 shadow-md rounded-md bg-gray-50 mb-2">
              <div className="flex w-full">
                <div className="flex flex-col flex-grow">
                  <Label className="text-base font-bold">
                    {transaction.description}
                  </Label>
                  <span className="text-sm text-gray-600">
                    {transaction.card?.name}
                  </span>
                </div>
                <div className={cx('grid items-center', transaction.type === 'credit' ? 'text-green-600' : 'text-red-600')}>
                  <span className="font-bold text-lg">{`$${transaction.amount}`}</span>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <TransactionCreateButton />
    </>
  );
}

export default HomePage;
