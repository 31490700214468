/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import cx from 'classnames';
import { Label, Select, TextInput } from 'flowbite-react';
import { useState } from 'react';

import { MdAdd } from 'react-icons/md';

import useCards from '@/api/useCards';
import useTransaction from '@/api/useTransaction';
import useForm from '@/hooks/useForm';

const INITIAL_FORM = {
  amount: '',
  description: '',
  date: '',
  card: 0,
};

function TransactionCreateButton() {
  const [isOpen, setIsOpen] = useState(false);
  const [type, setType] = useState<'credit' | 'debit'>('debit');
  const transaction = useTransaction();
  const cards = useCards();

  const {
    amount, description, date, card,
    handleInputChange, reset,
  } = useForm(INITIAL_FORM);

  const handleOpenForm = () => {
    reset(INITIAL_FORM);
    setIsOpen(true);
  };

  const handleSubmitTransaction = (e: any) => {
    e.preventDefault();
    transaction.mutateAsync({
      amount: Number(amount),
      description,
      type,
      card_id: card,
    }).then((res) => {
      console.log(res);
      setIsOpen(false);
    });
  };

  if (!cards?.data?.data) return null;

  return (
    <>
      <button
        type="button"
        className="btn btn-floating bottom-20 right-4"
        onClick={handleOpenForm}
      >
        <MdAdd />
      </button>
      <div
        className={cx('bg-black opacity-10 fixed w-full h-screen left-0 right-0 top-0', {
          block: isOpen,
          hidden: !isOpen,
        })}
        onClick={() => setIsOpen(false)}
      />
      <div className={cx(
        'z-[90] h-[80vh] w-full fixed bottom-0 bg-white rounded-t-xl p-4 left-0 right-0 shadow-inner',
        'transition-transform duration-500 ease-in-out',
        {
          'transform translate-y-0': isOpen,
          'transform translate-y-full': !isOpen,
        },
      )}
      >
        <h2 className="mb-4">Agregar un movimiento</h2>
        <form onSubmit={handleSubmitTransaction}>
          <div className="form-group">
            <div className="flex w-full">
              <button
                type="button"
                className={cx('btn flex-grow rounded-r-none', { 'btn-outline': type === 'debit' })}
                onClick={() => setType('debit')}
              >
                Gasto
              </button>
              <button
                type="button"
                className={cx('btn flex-grow rounded-l-none', { 'btn-outline': type === 'credit' })}
                onClick={() => setType('credit')}
              >
                Ingreso
              </button>
            </div>
          </div>
          <div className="form-group">
            <Label htmlFor="card">Cuenta</Label>
            <Select onChange={(e) => handleInputChange(e.target)} name="card" id="card" value={card}>
              <option value={0}>Selecciona una cuenta</option>
              {cards.data?.data.map((item) => (
                <option key={item.id} value={item.id}>{item.name}</option>
              ))}
            </Select>
          </div>
          <div className="form-group">
            <Label htmlFor="date">Fecha</Label>
            <TextInput name="date" id="date" placeholder="Fecha" type="date" value={date} onChange={(e) => handleInputChange(e.target)} />
          </div>
          <div className="form-group">
            <Label htmlFor="amount">Monto</Label>
            <TextInput name="amount" id="amount" placeholder="Monto" type="number" value={amount} onChange={(e) => handleInputChange(e.target)} />
          </div>
          <div className="form-group">
            <Label htmlFor="description">Descripci&oacute;n</Label>
            <TextInput name="description" id="description" placeholder="Descripci&oacute;n" type="text" value={description} onChange={(e) => handleInputChange(e.target)} />
          </div>
          <div className="w-full flex justify-end gap-4 mt-4">
            <button
              type="button"
              className="btn btn-outline"
              onClick={() => setIsOpen(false)}
            >
              Cancelar
            </button>
            <button
              type="submit"
              className="btn"
            >
              Guardar
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default TransactionCreateButton;
