import { ReactNode } from 'react';

import { useSignIn } from '@/api/authentication';
import LoadingScreen from '@/components/LoadingScreen';
import { AuthContext, AuthContextInterface } from './AuthContext';

export function AuthProvider({ children }: { children: ReactNode }) {
  const signIn = useSignIn();

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const contextValue: AuthContextInterface = {
    user: signIn?.signedInUser ? signIn.signedInUser : null,
    isAuthenticated: !!signIn.signedInUser,
    token: signIn?.token,
  };

  if (signIn.isLoading) {
    return (
      <div>
        <LoadingScreen />
      </div>
    );
  }

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
}

export default AuthProvider;
