/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */

import Header from '@/components/Header';

function TermsPage() {
  return (
    <>
      <Header />

      <div className="max-w-7xl py-20 m-auto text-justify flex flex-col gap-2">
        <h1 className="mb-4">TÉRMINOS Y CONDICIONES</h1>

        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat obcaecati maxime unde molestias inventore culpa maiores consequuntur deleniti sint, rerum tenetur minus consequatur illum possimus ipsum nihil incidunt earum cum.</p>

      </div>
    </>
  );
}

export default TermsPage;
