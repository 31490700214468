import { createContext } from 'react';

export interface AuthContextInterface {
  user: Api.User | null;
  isAuthenticated: boolean;
  token?: string;
}

export const authContextDefaultValues: AuthContextInterface = {
  user: null,
  isAuthenticated: false,
};

export const AuthContext = createContext<AuthContextInterface>(authContextDefaultValues);
