import { Navbar as FlowbiteNavbar } from 'flowbite-react';

import UserAvatarDropdown from '@/features/UserAvatarDropdown';
import useAuth from '@/hooks/useAuth';

function Header() {
  const { user } = useAuth();

  if (!user) return null;

  return (
    <header className="sticky top-0 z-50">
      <FlowbiteNavbar
        fluid
        rounded
        className="bg-white shadow-sm"
      >
        <FlowbiteNavbar.Brand>
          <img
            alt="logo"
            className="mr-3 h-14 object-contain"
            src="/logo.png"
          />
        </FlowbiteNavbar.Brand>
        <div className="flex gap-5 items-center">
          <UserAvatarDropdown />
        </div>
      </FlowbiteNavbar>
    </header>
  );
}

export default Header;
