function FetchingIndicator() {
  return (
    <div className="absolute -top-1 -right-1">
      <span className="relative flex h-3 w-3">
        <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-main opacity-75" />
        <span className="relative inline-flex rounded-full h-3 w-3 bg-main" />
      </span>
    </div>
  );
}

export default FetchingIndicator;
