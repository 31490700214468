import { useMutation } from 'react-query';

import useAuth from '@/hooks/useAuth';
import { axios } from './axios';

// should receive a card object and initial balance
async function postCard(card: Partial<Api.Card> & { initial_balance: number }, csrfToken?: string) {
  const res = await axios.post<Api.Response<Api.Card>>('/cards', card, {
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': csrfToken,
    },
  });
  console.log({
    'X-CSRF-TOKEN': csrfToken,
  });
  return res.data;
}

const makeCard = (csrfToken?: string) => {
  const card = useMutation((data: Partial<Api.Card> & { initial_balance: number }) => postCard(data, csrfToken));
  return card;
};

const useCard = () => {
  const { token } = useAuth();
  return makeCard(token);
};

export default useCard;
